export default {
  appname: "overpass-turbo-ogf",
  settingNamespace: "overpass-ide-ogf",
  defaultServer: "https://overpass.opengeofiction.net/api/",
  suggestedServers: [
    "https://overpass.opengeofiction.net/api/",
  ],
  defaultTiles: "https://tiles05.opengeofiction.net/ogf-carto/{z}/{x}/{y}.png",
  tileServerAttribution: `© <a href="https://opengeofiction.net/copyright">OpenGeofiction</a> contributors`,
  suggestedTiles: [
    "https://tiles05.opengeofiction.net/ogf-carto/{z}/{x}/{y}.png",
    "https://tile.opengeofiction.net/ogf-carto/{z}/{x}/{y}.png"
  ],
  defaultMapView: {
    lat: 20.718,
    lon: 86.718,
    zoom: 13
  },
  maxMapZoom: 19,
  short_url_service: "",
  push_history_url: true,
  html2canvas_use_proxy: false,
  osmnamesApiKey: "",
  osmAuth: {
    url: "https://opengeofiction.net",
    client_id: "BZUH3Duiky0hgB5Rpg-Nt44vvRWWDC02vWdYaKku2Xc"
  }
};
